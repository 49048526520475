<template>
  <div>
    <el-cascader
      :options="options"
      v-model="list"
      popper-class="location-cascader"
      style="width: 100%;"
      @change="change"
      size="small"
    >
      <template slot-scope="{ node, data }">
        <div class="location-cascader-item">
          <div class="content" :class="{ disabled: data.disabled }">
            <div class="title">
              <span>{{ data.label }}</span>
              <span v-if="node.level == 1">({{ data.name }})</span>
            </div>
            <div class="desc">{{ returnText(data) }}</div>
          </div>

          <div class="status danger" v-if="data.disabled">Disabled</div>
        </div>
      </template>
    </el-cascader>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array
    }
  },

  data() {
    return {};
  },

  computed: {
    options() {
      return this.$store.state.app.locationList;
    },

    list: {
      get() {
        return this.data;
      },

      set() {}
    }
  },

  methods: {
    returnText(data) {
      let text = "";
      if (data.type == "vendor") text = this.$t("vendor");
      if (data.type == "region") text = this.$t("region");
      if (data.type == "cluster") text = this.$t("Cluster");

      return text;
    },

    change(val) {
      this.$emit("update:data", val);
      this.$emit("change");
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.location-cascader {
  .el-cascader-node {
    font-size: 12px;
    padding: 0;
    height: 40px;

    .el-cascader-node__label {
      box-sizing: inherit !important;
      display: block;
    }
  }

  .el-cascader-menu__wrap {
    height: 300px;
    .el-cascader-node__prefix {
      right: 10px !important;
      left: auto;
    }
  }
}

.location-cascader-item {
  @include flex(flex-start, center, nowrap);

  & > .content {
    flex: 1;
    overflow: hidden;
    line-height: 20px;

    .title {
      font-size: $normal-font-size;
      font-weight: 600;
    }

    .desc {
      font-size: $normal-font-size;
      color: $color-sub;
    }

    &.disabled {
      .title,
      .desc {
        color: #bcbec2;
      }
    }
  }
}
</style>
