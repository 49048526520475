<template>
  <div>
    <div class="application-conf-form">
      <a-steps v-model="activeStep" type="navigation" size="small" :style="stepStyle">
        <a-step title="Step 1" :description="$t('applicationSteps.first')" disabled />
        <a-step title="Step 2" :description="$t('applicationSteps.second')" disabled />
        <a-step title="Step 3" :description="$t('applicationSteps.third')" disabled />
      </a-steps>

      <div v-if="activeStep == 0">
        <el-form label-position="top" size="small">
          <el-form-item :label="$t('applicationType')">
            <el-radio-group v-model="type" @change="typeChange">
              <el-radio-button label="Deployment">{{ $t("Deployment") }}</el-radio-button>
              <el-radio-button label="StatefulSet">{{ $t("StatefulSet") }}</el-radio-button>
              <el-radio-button label="CronJob">{{ $t("CronJob") }}</el-radio-button>
              <el-radio-button label="DaemonSet">{{ $t("DaemonSet") }}</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('location')" required>
                <checkout-list :data.sync="locationList" />
                <span class="help">{{ $t("tips.location") }}</span>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('Namespace')" required>
                <type-list
                  type="namespace"
                  :data.sync="form.metadata.namespace"
                  @change="namespaceChange"
                  :locationList="locationList"
                />

                <span class="help">{{ $t("tips.checkoutNamespace") }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <basic-conf
          ref="basicConf"
          :form="form"
          :vendor="locationList[0]"
          :region="locationList[1]"
          :cluster="locationList[2]"
        />
      </div>

      <div v-if="activeStep == 1">
        <el-button type="primary" size="small" @click="addContainer" plain style="margin-bottom: 10px">
          {{ $t("containerAdd") }}
        </el-button>

        <div class="container-item" v-for="(item, index) in getContainer()" :key="item.name">
          <svg-icon icon-class="Pod"></svg-icon>

          <div class="content">
            <div class="title">{{ item.name }}</div>
            <div class="desc">{{ item.image }}</div>
          </div>

          <div>
            <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom" v-show="!item.edit">
              <svg-icon icon-class="edit" class-name="handle-icon" @click.native="editContainer(item)"></svg-icon>
            </el-tooltip>

            <el-tooltip effect="dark" :content="$t('handle.delete')" placement="bottom">
              <svg-icon
                icon-class="delete"
                class-name="handle-icon"
                @click.native="deleteContainer(item, index)"
              ></svg-icon>
            </el-tooltip>
          </div>
        </div>

        <el-card v-if="drawer" shadow="never">
          <div slot="header" class="clearfix">
            <span>{{ $t("containerConf") }}</span>
            <el-button style="float: right; padding: 3px 0" type="text" size="small" @click="drawer = false">
              {{ $t("handle.delete") }}
            </el-button>
          </div>

          <container-conf
            :containerForm="containerForm"
            ref="containerForm"
            v-if="drawer"
            :namespace="form.metadata.namespace"
            :locationList="locationList"
          >
            <template #confMap>
              <config-map
                :namespace="form.metadata.namespace"
                :configs="configMapForm.configs"
                :disabledNames="[]"
                ref="configMapForm"
                :locationList="locationList"
                type="configMap"
              />
            </template>

            <template #secret>
              <config-map
                :namespace="form.metadata.namespace"
                :configs="secretForm.configs"
                :disabledNames="[]"
                ref="secretForm"
                :locationList="locationList"
                type="secret"
              />
            </template>

            <template #volume>
              <volume-conf :volumes="volumeForm.volumes" ref="volumeForm" />
            </template>

            <template #pvc>
              <pvc-conf
                :namespace="form.metadata.namespace"
                :pvc="pvcForm.persistentVolumeClaims"
                :disabledNames="existedPersistentVolumeClaimsNames"
                :locationList="locationList"
                ref="pvcForm"
              />
            </template>
          </container-conf>

          <div style="margin-top: 10px;">
            <el-button type="primary" size="small" plain @click="containerSubmit">
              {{ drawerFormType == "add" ? $t("handle.add") : $t("handle.save") }}
            </el-button>
          </div>
        </el-card>
      </div>

      <div v-if="activeStep == 2">
        <el-button type="primary" size="small" @click="addService" plain style="margin-bottom: 10px;">
          {{ $t("handle.addService") }}
        </el-button>

        <div class="service-item" v-for="(item, index) in form.spec.services" :key="item.metadata.name">
          <svg-icon icon-class="Service"></svg-icon>

          <div class="content">
            <div class="title">{{ item.metadata.name }}</div>
            <div class="desc">{{ $t("serviceName") }}</div>
          </div>

          <div>
            <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom" v-show="!item.edit">
              <svg-icon icon-class="edit" class-name="handle-icon" @click.native="editService(item)"></svg-icon>
            </el-tooltip>

            <el-tooltip effect="dark" :content="$t('handle.delete')" placement="bottom">
              <svg-icon
                icon-class="delete"
                class-name="handle-icon"
                @click.native="form.spec.services.splice(index, 1)"
              ></svg-icon>
            </el-tooltip>
          </div>
        </div>

        <el-card v-if="dialogVisible" shadow="never">
          <div slot="header" class="clearfix">
            <span>{{ $t("containerConf") }}</span>
            <el-button style="float: right; padding: 3px 0" type="text" size="small" @click="dialogVisible = false">
              {{ $t("handle.delete") }}
            </el-button>
          </div>

          <service-conf
            ref="serviceForm"
            :serviceForm="serviceForm"
            :namespace="form.metadata.namespace"
            :locationList="locationList"
            v-if="dialogVisible"
          />

          <div style="margin-top: 10px;">
            <el-button type="primary" @click="serviceFormSubmit" size="small" :loading="addServiceLoading">
              {{ $t("handle.submit") }}
            </el-button>
          </div>
        </el-card>
      </div>

      <div class="step-handle-btn">
        <el-button
          type="primary"
          size="small"
          @click="nextStep"
          :disabled="drawer && activeStep == 1"
          v-if="activeStep < 2"
        >
          <el-tooltip
            effect="dark"
            :content="$t('tips.containerConfTooltipText')"
            placement="top-start"
            v-if="drawer && activeStep == 1"
          >
            <i class="el-icon-info"></i>
          </el-tooltip>

          {{ $t("handle.nextStep") }}
        </el-button>

        <el-button
          type="primary"
          size="small"
          v-if="activeStep > 1"
          @click="submit"
          :loading="submitLoading"
          :disabled="dialogVisible && activeStep == 2"
        >
          <el-tooltip
            effect="dark"
            :content="$t('tips.serviceConfTooltipText')"
            placement="top-start"
            v-if="dialogVisible && activeStep == 2"
          >
            <i class="el-icon-info"></i>
          </el-tooltip>

          {{ $t("handle.submit") }}
        </el-button>

        <el-button type="primary" size="small" @click="activeStep--" v-if="activeStep > 0">
          {{ $t("handle.lastStep") }}
        </el-button>
      </div>

      <aside class="yaml">
        <editor v-model="yamlText" @init="editorInit" lang="yaml" theme="cobalt" width="100%" height="700"></editor>
      </aside>
    </div>

    <el-dialog
      :title="$t('prompt')"
      :visible.sync="importDialogVisible"
      :close-on-click-modal="false"
      width="800px"
      top="30px"
    >
      <span class="help">{{ $t("addApplication.import") }}</span>
      <editor v-model="importText" @init="editorInit" lang="yaml" theme="cobalt" width="100%" height="300"> </editor>
      <span slot="footer">
        <el-button type="primary" size="small" @click="cancelImport">
          {{ $t("handle.cancelImport") }}
        </el-button>

        <el-button type="primary" size="small" @click="importForm">
          {{ $t("handle.import") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const uuidv4 = require("uuid/v4");
import BasicConf from "./BasicConf";
import ContainerConf from "./ContainerConf";
import ServiceConf from "./ServiceConf";
import ConfigMap from "./ConfigMap";
import PvcConf from "./PvcConf";
import VolumeConf from "./VolumeConf";
import CheckoutList from "@/components/CheckoutList";

import { resourceHandler, initResource, affinityHandler, imagePullSecretsHandler } from "./utils";
import { cloneDeep, includes, startsWith, isEqual } from "lodash";
import { applicationSubmit } from "api/deployment";
import { serviceDetail } from "api/service";

const YAML = require("json2yaml");

export default {
  props: {},
  components: {
    ContainerConf,
    ServiceConf,
    BasicConf,
    ConfigMap,
    PvcConf,
    VolumeConf,
    CheckoutList,
    editor: require("vue2-ace-editor")
  },

  data() {
    return {
      drawer: false,
      dialogVisible: false,
      activeStep: 0,
      drawerFormType: "add",
      type: "Deployment",
      locationList: [],
      form: {
        metadata: {
          labels: {},
          name: "", //应用名称
          namespace: "", //命名空间
          annotations: {}
        },
        spec: {
          type: "Deployment",
          configMaps: [],
          secretMaps: [],
          persistentVolumeClaims: [],
          volumes: [],
          deployment: {
            spec: {
              replicas: 1, //副本
              strategy: {
                rollingUpdate: {
                  maxUnavailable: 0,
                  maxSurge: 3
                },
                type: "RollingUpdate" //滚动升级
              },
              template: {
                spec: {
                  affinity: {
                    nodeAffinity: {
                      requiredDuringSchedulingIgnoredDuringExecution: {
                        nodeSelectorTerms: [
                          {
                            matchExpressions: []
                          }
                        ]
                      }
                    },
                    podAffinity: {
                      requiredDuringSchedulingIgnoredDuringExecution: [
                        {
                          labelSelector: {
                            matchExpressions: []
                          },
                          namespaces: [],
                          topologyKey: ""
                        }
                      ]
                    },
                    podAntiAffinity: {
                      requiredDuringSchedulingIgnoredDuringExecution: [
                        {
                          labelSelector: {
                            matchExpressions: []
                          },
                          namespaces: [],
                          topologyKey: ""
                        }
                      ]
                    }
                  },
                  tolerations: [],
                  containers: [],
                  hostAliases: [],
                  terminationGracePeriodSeconds: 30
                }
              }
            }
          },
          services: []
        }
      },

      originForm: {},
      serviceForm: {
        metadata: {
          name: ""
        },
        spec: {
          ports: [
            {
              name: "port1",
              port: 8080,
              protocol: "TCP"
            }
          ],
          type: "ClusterIP"
        }
      },
      serviceFormType: "add",
      configMapForm: { configs: [] },
      secretForm: { configs: [] },
      pvcForm: { persistentVolumeClaims: [] },
      volumeForm: { volumes: [] },
      containerForm: {},

      submitLoading: false,
      addServiceLoading: false,
      save: true,
      importDialogVisible: false,
      stepStyle: {
        marginBottom: "10px",
        boxShadow: "0px -1px 0 0 #e8e8e8 inset"
      }
    };
  },

  computed: {
    vendor() {
      return this.locationList[0];
    },

    region() {
      return this.locationList[1];
    },

    cluster() {
      return this.locationList[2];
    },

    yamlText: {
      get() {
        return YAML.stringify(this.form);
      },

      set() {}
    },

    importText: {
      get() {
        return localStorage.form ? YAML.stringify(JSON.parse(localStorage.form)) : "";
      },

      set() {}
    },

    existedConfigMapNames() {
      let names = [];
      this.form.spec.configMaps.forEach(item => {
        item.configs.forEach(config => {
          names.push(config.name);
        });
      });

      return names;
    },

    existedPersistentVolumeClaimsNames() {
      let names = [];
      this.form.spec.persistentVolumeClaims.forEach(item => {
        item.persistentVolumeClaims.forEach(pvc => {
          names.push(pvc.claimName);
        });
      });

      return names;
    }
  },

  methods: {
    nextStep() {
      if (this.activeStep === 0) {
        this.$refs["basicConf"].$refs["form"].validate(valid => {
          if (valid) {
            if (
              Array.isArray(this.$refs["basicConf"].imagePullSecrets) &&
              this.$refs["basicConf"].imagePullSecrets.length > 0
            ) {
              let data = this.$refs["basicConf"].imagePullSecrets.map(item => {
                return { name: item };
              });

              imagePullSecretsHandler(this.form, data);
            }

            this.activeStep++;
          }
        });
      } else {
        this.activeStep++;
      }
    },

    editorInit(editor) {
      editor.setReadOnly(true);
      require("brace/ext/searchbox");
      require("brace/mode/yaml");
      require("brace/theme/cobalt");
    },

    typeChange(val) {
      delete this.form.spec.deployment;
      delete this.form.spec.cronjob;
      delete this.form.spec.daemonset;
      delete this.form.spec.statefulset;
      delete this.form.spec.volumeClaimTemplates;
      if (val === "Deployment") {
        this.$set(this.form.spec, "type", "Deployment");
        this.$set(this.form.spec, "services", []);
        this.$set(this.form.spec, "deployment", {
          spec: {
            replicas: 1, //副本
            strategy: {
              rollingUpdate: {
                maxUnavailable: 0,
                maxSurge: 3
              },
              type: "RollingUpdate" //滚动升级
            },
            template: {
              spec: {
                affinity: {
                  nodeAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: {
                      nodeSelectorTerms: [
                        {
                          matchExpressions: []
                        }
                      ]
                    }
                  },
                  podAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: [
                      {
                        labelSelector: {
                          matchExpressions: []
                        },
                        namespaces: [],
                        topologyKey: ""
                      }
                    ]
                  },
                  podAntiAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: [
                      {
                        labelSelector: {
                          matchExpressions: []
                        },
                        namespaces: [],
                        topologyKey: ""
                      }
                    ]
                  }
                },
                tolerations: [],
                containers: [],
                hostAliases: [],
                terminationGracePeriodSeconds: 30
              }
            }
          }
        });
      }

      if (val === "StatefulSet") {
        this.$set(this.form.spec, "volumeClaimTemplates", []);
        this.$set(this.form.spec, "type", "StatefulSet");
        this.$set(this.form.spec, "services", []);
        this.$set(this.form.spec, "statefulset", {
          spec: {
            podManagementPolicy: "OrderedReady",
            replicas: 1,
            updateStrategy: {
              rollingUpdate: {
                partition: 0
              },
              type: "RollingUpdate"
            },
            template: {
              spec: {
                affinity: {
                  nodeAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: {
                      nodeSelectorTerms: [
                        {
                          matchExpressions: []
                        }
                      ]
                    }
                  },
                  podAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: [
                      {
                        labelSelector: {
                          matchExpressions: []
                        },
                        namespaces: [],
                        topologyKey: ""
                      }
                    ]
                  },
                  podAntiAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: [
                      {
                        labelSelector: {
                          matchExpressions: []
                        },
                        namespaces: [],
                        topologyKey: ""
                      }
                    ]
                  }
                },
                tolerations: [],
                containers: [],
                hostAliases: [],
                terminationGracePeriodSeconds: 30
              }
            }
          }
        });
      }

      if (val === "CronJob") {
        this.$set(this.form.spec, "type", "CronJob");
        this.$set(this.form.spec, "cronjob", {
          spec: {
            concurrencyPolicy: "Forbid",
            failedJobsHistoryLimit: 10,
            schedule: "",
            startingDeadlineSeconds: 10,
            successfulJobsHistoryLimit: 10,
            suspend: false,
            jobTemplate: {
              spec: {
                activeDeadlineSeconds: 600,
                backoffLimit: 6,
                parallelism: 1,
                completions: 1,
                template: {
                  spec: {
                    affinity: {
                      nodeAffinity: {
                        requiredDuringSchedulingIgnoredDuringExecution: {
                          nodeSelectorTerms: [
                            {
                              matchExpressions: []
                            }
                          ]
                        }
                      },
                      podAffinity: {
                        requiredDuringSchedulingIgnoredDuringExecution: [
                          {
                            labelSelector: {
                              matchExpressions: []
                            },
                            namespaces: [],
                            topologyKey: ""
                          }
                        ]
                      },
                      podAntiAffinity: {
                        requiredDuringSchedulingIgnoredDuringExecution: [
                          {
                            labelSelector: {
                              matchExpressions: []
                            },
                            namespaces: [],
                            topologyKey: ""
                          }
                        ]
                      }
                    },
                    tolerations: [],
                    containers: [],
                    hostAliases: [],
                    restartPolicy: "OnFailure"
                  }
                }
              }
            }
          }
        });
      }

      if (val === "DaemonSet") {
        this.$set(this.form.spec, "type", "DaemonSet");
        this.$set(this.form.spec, "services", []);
        this.$set(this.form.spec, "daemonset", {
          spec: {
            updateStrategy: {
              rollingUpdate: {
                maxUnavailable: 0
              },
              type: "RollingUpdate" //滚动升级
            },
            template: {
              spec: {
                affinity: {
                  nodeAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: {
                      nodeSelectorTerms: [
                        {
                          matchExpressions: []
                        }
                      ]
                    }
                  },
                  podAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: [
                      {
                        labelSelector: {
                          matchExpressions: []
                        },
                        namespaces: [],
                        topologyKey: ""
                      }
                    ]
                  },
                  podAntiAffinity: {
                    requiredDuringSchedulingIgnoredDuringExecution: [
                      {
                        labelSelector: {
                          matchExpressions: []
                        },
                        namespaces: [],
                        topologyKey: ""
                      }
                    ]
                  }
                },
                tolerations: [],
                containers: [],
                hostAliases: [],
                terminationGracePeriodSeconds: 30
              }
            }
          }
        });
      }
    },

    getContainer() {
      if (this.form.spec.type == "Deployment") {
        return this.form.spec.deployment.spec.template.spec.containers;
      }

      if (this.form.spec.type == "StatefulSet") {
        return this.form.spec.statefulset.spec.template.spec.containers;
      }

      if (this.form.spec.type == "CronJob") {
        return this.form.spec.cronjob.spec.jobTemplate.spec.template.spec.containers;
      }

      if (this.form.spec.type == "DaemonSet") {
        return this.form.spec.daemonset.spec.template.spec.containers;
      }
    },

    drawerOpen() {
      if (this.drawerFormType == "add") {
        this.containerForm = {
          id: uuidv4(),
          name: "",
          env: [],
          image: "",
          ports: [{ name: `port1`, containerPort: 8080, protocol: "TCP" }],
          resources: {
            cpuOversell: 50,
            memOversell: 50,
            limits: {
              cpu: 2,
              cpuUnit: "Core",
              memory: 2,
              memoryUnit: "G"
            },
            requests: {
              cpu: "",
              memory: ""
            }
          }
        };

        this.configMapForm = { configs: [] };
        this.secretForm = { configs: [] };
        this.pvcForm = { persistentVolumeClaims: [] };
        this.volumeForm = { volumes: [] };
      }
    },

    addService() {
      if (this.locationList.length > 0 && this.form.metadata.namespace) {
        this.dialogVisible = true;
        this.serviceFormType = "add";
        this.serviceForm = {
          id: uuidv4(),
          metadata: {
            name: "",
            annotations: {
              "ingress-access-type": "host",
              "ingress-host": "",
              "ingress-port": "",
              "ingress-subpath": "",
              "scheme-type": "internet-facing,internal"
            }
          },

          spec: {
            ports: [
              {
                name: "port1",
                port: 8080,
                targetPort: 8080,
                protocol: "TCP"
              }
            ],
            type: "ClusterIP"
          }
        };
      } else {
        this.$notify.error({
          title: "Error",
          message: this.$t("rules.location")
        });
      }
    },

    editService(row) {
      this.dialogVisible = true;
      this.serviceFormType = "edit";
      this.serviceForm = cloneDeep(row);
    },

    serviceFormSubmit() {
      this.$refs["serviceForm"].$refs["form"].validate(valid => {
        if (valid) {
          let existValid = true;
          let exists = this.form.spec.services.map(item => item.metadata.name);

          if (includes(exists, this.$refs["serviceForm"].form.metadata.name)) {
            if (this.serviceFormType == "add") {
              existValid = false;
            } else if (this.serviceFormType == "edit") {
              this.form.spec.services.forEach(item => {
                if (item.metadata.name == this.$refs["serviceForm"].form.metadata.name) {
                  if (item.id != this.$refs["serviceForm"].form.id) existValid = false;
                }
              });
            }
          }

          if (existValid) {
            this.addServiceLoading = true;
            serviceDetail(
              {
                vendor: this.locationList[0],
                region: this.locationList[1],
                cluster: this.locationList[2],
                namespace: this.form.metadata.namespace,
                service: this.$refs["serviceForm"].form.metadata.name
              },
              {
                status: this.$refs["serviceForm"].form.metadata.name
              }
            ).then(response => {
              if (response.code === 0) {
                this.addServiceLoading = false;
                if (!response.data) {
                  if (
                    this.$refs["serviceForm"].form.spec.type == "NodePort" &&
                    this.$refs["serviceForm"].form.metadata.annotations["ingress-access-type"] == "subpath"
                  ) {
                    if (!startsWith(this.$refs["serviceForm"].form.metadata.annotations["ingress-subpath"], "/")) {
                      this.$refs["serviceForm"].form.metadata.annotations["ingress-subpath"] =
                        "/" + this.$refs["serviceForm"].form.metadata.annotations["ingress-subpath"];
                    }
                  }

                  if (this.serviceFormType == "add") {
                    this.form.spec.services.push(cloneDeep(this.$refs["serviceForm"].form));
                  }

                  if (this.serviceFormType == "edit") {
                    this.form.spec.services.forEach((item, index) => {
                      if (item.id == this.$refs["serviceForm"].form.id) {
                        this.form.spec.services.splice(index, 1, cloneDeep(this.$refs["serviceForm"].form));
                      }
                    });
                  }

                  this.dialogVisible = false;
                } else {
                  this.$notify.error({
                    title: "Error",
                    message: this.$t("rules.existed", [this.$refs["serviceForm"].form.metadata.name])
                  });
                }
              }
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: this.$t("rules.existed", [this.$refs["serviceForm"].form.metadata.name])
            });
          }
        }
      });
    },

    addContainer() {
      if (this.locationList.length > 0 && this.form.metadata.namespace) {
        this.drawer = true;
        this.drawerFormType = "add";
        this.drawerOpen();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.$t("rules.location")
        });
      }
    },

    editContainer(row) {
      this.drawer = true;
      this.containerForm = cloneDeep(row);
      initResource(this.containerForm.resources);
      this.drawerFormType = "edit";

      for (let item of this.form.spec.configMaps) {
        if (row.id == item.id && row.name == item.container) {
          this.configMapForm = item;
          break;
        }
      }

      for (let item of this.form.spec.secretMaps) {
        if (row.id == item.id && row.name == item.container) {
          this.secretForm = item;
          break;
        }
      }

      for (let item of this.form.spec.persistentVolumeClaims) {
        if (row.id == item.id && row.name == item.container) {
          this.pvcForm = item;
          break;
        }
      }

      for (let item of this.form.spec.volumes) {
        if (row.id == item.id && row.name == item.container) {
          this.volumeForm = item;
          break;
        }
      }
    },

    containerSubmit() {
      let currentContainer = this.getContainer();

      this.$refs["containerForm"].$refs["form"].validate(valid => {
        if (valid) {
          this.$refs["configMapForm"].$refs["form"].validate(valid => {
            if (valid) {
              this.$refs["secretForm"].$refs["form"].validate(valid => {
                if (valid) {
                  this.$refs["pvcForm"].$refs["form"].validate(valid => {
                    if (valid) {
                      this.$refs["volumeForm"].$refs["form"].validate(valid => {
                        if (valid) {
                          let existValid = true;
                          let exists = currentContainer.map(item => item.name);

                          if (includes(exists, this.$refs["containerForm"].form.name)) {
                            if (this.drawerFormType == "add") {
                              existValid = false;
                            } else if (this.drawerFormType == "edit") {
                              currentContainer.forEach(item => {
                                if (item.name == this.$refs["containerForm"].form.name) {
                                  if (item.id != this.$refs["containerForm"].form.id) existValid = false;
                                }
                              });
                            }
                          }

                          if (existValid) {
                            if (this.$refs["containerForm"].visible.startCommand) {
                              if (this.$refs["containerForm"].form.command.length < 1) {
                                delete this.$refs["containerForm"].form.command;
                              }

                              if (this.$refs["containerForm"].form.args.length < 1) {
                                delete this.$refs["containerForm"].form.args;
                              }
                            }

                            // 深拷贝ConfigMap组件数据
                            let configsTemp = cloneDeep(this.$refs["configMapForm"].form.configs);
                            let secretTemp = cloneDeep(this.$refs["secretForm"].form.configs);
                            let pvcTemp = cloneDeep(this.$refs["pvcForm"].form.persistentVolumeClaims);
                            let volumeTemp = cloneDeep(this.$refs["volumeForm"].form.volumes);

                            resourceHandler(this.$refs["containerForm"].form.resources);

                            if (this.drawerFormType == "add") {
                              currentContainer.push(cloneDeep(this.$refs["containerForm"].form));

                              this.form.spec.configMaps.push({
                                container: this.$refs["containerForm"].form.name,
                                id: this.$refs["containerForm"].form.id,
                                configs: [...configsTemp]
                              });

                              this.form.spec.secretMaps.push({
                                container: this.$refs["containerForm"].form.name,
                                id: this.$refs["containerForm"].form.id,
                                configs: [...secretTemp]
                              });

                              this.form.spec.persistentVolumeClaims.push({
                                container: this.$refs["containerForm"].form.name,
                                id: this.$refs["containerForm"].form.id,
                                persistentVolumeClaims: [...pvcTemp]
                              });

                              this.form.spec.volumes.push({
                                container: this.$refs["containerForm"].form.name,
                                id: this.$refs["containerForm"].form.id,
                                volumes: [...volumeTemp]
                              });
                            }

                            if (this.drawerFormType == "edit") {
                              currentContainer.forEach((item, index) => {
                                if (item.id == this.$refs["containerForm"].form.id) {
                                  currentContainer.splice(index, 1, cloneDeep(this.$refs["containerForm"].form));
                                }
                              });

                              this.form.spec.configMaps.forEach((item, index, arr) => {
                                if (item.id == this.$refs["containerForm"].form.id) {
                                  arr.splice(index, 1, {
                                    container: this.$refs["containerForm"].form.name,
                                    id: this.$refs["containerForm"].form.id,
                                    configs: [...configsTemp]
                                  });
                                }
                              });

                              this.form.spec.secretMaps.forEach((item, index, arr) => {
                                if (item.id == this.$refs["containerForm"].form.id) {
                                  arr.splice(index, 1, {
                                    container: this.$refs["containerForm"].form.name,
                                    id: this.$refs["containerForm"].form.id,
                                    configs: [...secretTemp]
                                  });
                                }
                              });

                              this.form.spec.persistentVolumeClaims.forEach((item, index, arr) => {
                                if (item.id == this.$refs["containerForm"].form.id) {
                                  arr.splice(index, 1, {
                                    container: this.$refs["containerForm"].form.name,
                                    id: this.$refs["containerForm"].form.id,
                                    persistentVolumeClaims: [...pvcTemp]
                                  });
                                }
                              });

                              this.form.spec.volumes.forEach((item, index, arr) => {
                                if (item.id == this.$refs["containerForm"].form.id) {
                                  arr.splice(index, 1, {
                                    container: this.$refs["containerForm"].form.name,
                                    id: this.$refs["containerForm"].form.id,
                                    volumes: [...volumeTemp]
                                  });
                                }
                              });
                            }

                            this.drawer = false;
                          } else {
                            this.$notify.error({
                              title: "WARNING",
                              message: this.$t("rules.existed", [this.$refs["containerForm"].form.name])
                            });
                          }
                        } else {
                          this.$refs["containerForm"].collapseVal = "volume";
                        }
                      });
                    } else {
                      this.$refs["containerForm"].collapseVal = "volume";
                    }
                  });
                } else {
                  this.$refs["containerForm"].collapseVal = "config";
                }
              });
            } else {
              this.$refs["containerForm"].collapseVal = "config";
            }
          });
        }
      });
    },

    deleteContainer(row, index) {
      let currentContainer = this.getContainer();
      currentContainer.splice(index, 1);

      this.form.spec.configMaps.forEach((item, index) => {
        if (row.id == item.id && row.name == item.container) {
          this.form.spec.configMaps.splice(index, 1);
        }
      });

      this.form.spec.secretMaps.forEach((item, index) => {
        if (row.id == item.id && row.name == item.container) {
          this.form.spec.secretMaps.splice(index, 1);
        }
      });

      this.form.spec.persistentVolumeClaims.forEach((item, index) => {
        if (row.id == item.id && row.name == item.container) {
          this.form.spec.persistentVolumeClaims.splice(index, 1);
        }
      });

      this.form.spec.volumes.forEach((item, index) => {
        if (row.id == item.id && row.name == item.container) {
          this.form.spec.volumes.splice(index, 1);
        }
      });
    },

    submit() {
      this.$set(this.form.metadata.labels, "app", this.form.metadata.name);

      let type = this.form.spec.type.toLowerCase();

      this.form.metadata.annotations[`${type}.k8s.kubestar.io/vendor`] = this.vendor;
      this.form.metadata.annotations[`${type}.k8s.kubestar.io/region`] = this.region;
      this.form.metadata.annotations[`${type}.k8s.kubestar.io/cluster`] = this.cluster;

      this.submitLoading = true;

      if (this.form.spec.type != "CronJob") {
        if (!this.form.spec[type].spec.template.spec.terminationGracePeriodSeconds)
          delete this.form.spec[type].spec.template.spec.terminationGracePeriodSeconds;
      }

      affinityHandler(this.form);

      applicationSubmit(this.form, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
        response => {
          this.submitLoading = false;
          if (response.code === 0) {
            this.save = false;
            localStorage.removeItem("form");
            localStorage.removeItem("locationList");

            this.$router.push({ path: `/list/${this.type}` });
          } else {
            this.save = true;
            localStorage.form = JSON.stringify(this.form);
            localStorage.locationList = JSON.stringify(this.locationList);
          }
        }
      );
    },

    importForm() {
      this.form = JSON.parse(localStorage.form);
      if (!this.form.spec.secretMaps) this.form.spec.secretMaps = [];
      this.locationList = JSON.parse(localStorage.locationList);
      this.type = JSON.parse(localStorage.form).spec.type;

      this.importDialogVisible = false;
    },

    cancelImport() {
      this.importDialogVisible = false;
      localStorage.removeItem("form");
      localStorage.removeItem("locationList");
    },

    namespaceChange() {
      while (this.getContainer().length > 0) {
        this.getContainer().pop();
      }

      while (this.form.spec.configMaps.length > 0) {
        this.form.spec.configMaps.pop();
      }

      while (this.form.spec.secretMaps.length > 0) {
        this.form.spec.secretMaps.pop();
      }

      while (this.form.spec.persistentVolumeClaims.length > 0) {
        this.form.spec.persistentVolumeClaims.pop();
      }

      while (this.form.spec.volumes.length > 0) {
        this.form.spec.volumes.pop();
      }
    }
  },

  mounted() {
    this.originForm = cloneDeep(this.form);

    if (localStorage.form) {
      this.importDialogVisible = true;
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.save && !isEqual(this.originForm, this.form)) {
      const answer = window.confirm(this.$t("addApplication.leavePrompt"));
      if (answer) {
        localStorage.form = JSON.stringify(this.form);
        localStorage.locationList = JSON.stringify(this.locationList);

        next();
      } else {
        //next();
      }
    } else {
      next();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.application-conf-form {
  margin-right: 500px;
  position: relative;

  .step-handle-btn {
    margin-top: 10px;
  }
}

.container-item,
.service-item {
  @include resource-card();
  margin-bottom: 10px;
}

.yaml {
  width: 400px;
  position: fixed;
  top: 80px;
  right: 60px;
  border: 1px solid #ccd2d9;
  border-radius: 4px;
  font-size: 12px;
  background-color: #f5f2f0;

  .yaml-details {
    max-height: 500px;
    overflow: scroll;
  }
}
</style>
